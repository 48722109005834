import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"

function Testimonials() {
  return (
    <Layout>
      <SEO title="Home" />
      <Hero
        title="Testimonials"
        image="https://images.unsplash.com/photo-1529636600939-9edeb1f9719f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
     
      >
     
      </Hero>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
        <h3> Testimonials Page</h3>
    </Layout>
  )
}

export default Testimonials
